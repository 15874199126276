import React from "react"
import ReactPlayer from 'react-player'
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`

const Video = ({ ...props }) => (
  <Wrapper>
    <ReactPlayer style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }} {...props} width="100%" height="100%" />
  </Wrapper>
)

export default Video