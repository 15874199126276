import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import classNames from 'classnames'
import styled from "styled-components"
import ReactFullpage from '@fullpage/react-fullpage'
import Logo from "../components/logo"
import Video from "../components/video"

import "./index.scss"

const CV = [
  {
    caption: 'Ich bin selbstständige Motion Designerin, Illustratorin und Freelance Art Director',
    text: 'Neben meiner Arbeit als freiberufliche Illustratorin, supporte ich Agenturen als Freelancerin bei ganzheitlichen Kampagnen, Social Media und Pitches.'
  },
  {
    caption: 'Kunden',
    text: 'Samsung, McDonald’s, Goodyear, Dunlop, LIDL, LBS, Commerz Real'
  }
]

const Title = styled.h2`
  font-size: 1.5em;
  text-align: center;
  color: #7f90a2;
  text-transform: uppercase;
  letter-spacing: .5em;
`

const Caption = styled.span`
  color: #7f90a2;
  text-transform: uppercase;
  letter-spacing: .2em;
  line-height: 24px;
`

const Card = styled.div`
  position: relative;
  display: block;
  padding: 8rem;
  border: 5px solid #7f90a2;
  background: #fff;
  margin-right: 64px;

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #dee3e9;
    z-index: -1;
    transform-origin: 0%;
    transform: translate(0, 0);
    border: 5px solid #7f90a2;
    transition-duration: 1s;
  }

  &:after {
    background: #7f90a2;
    z-index: -2;
  }
`

const ImageCard = styled.figure`
  position: relative;
  width: 100%;
  border: 5px solid #7f90a2;
  font-size: 0;
  margin: 0 64px 64px 0;

  &:before, figcaption:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #dee3e9;
    z-index: -1;
    transform: translate(0, 0);
    transition-duration: 1s;
  }

  img {
    margin: 0;
  }

  figcaption {
    position: absolute;
    top: -5px;
    right: -64px;
    background: #fff;
    padding: 2em;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-size: 12px;
    border: 5px solid #7f90a2;
  }
`

const Section = styled.div`
  &.fp-completely {
    .card:before {
      transform: translate(30px, 30px);
    }
    .card:after {
      transform: translate(60px, 60px);
    }
    .image-card:before {
      transform: translate(30px, 30px);
    }
    .image-card:before, figcaption:before {
      transform: translate(30px, 30px);
    }
  }
`

const Empty = ({ children }) => (
  <>{children}</>
)

const Page = ({ children }) => (
  <Section className="page">
    <div
      className="container"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3rem 1.5rem'
      }}>
      {children}
    </div>
  </Section>
)

const VideoSlide = ({ description, title, youtubeId, index, currentPage }) => (
  <div className="columns is-vcentered" style={{ width: '100%' }}>
    <div className="column is-one-quarter">
      <h3 style={{ marginBottom: '1rem' }}>
        <Caption>{title}</Caption>
      </h3>
      {description?.description}
    </div>
    <div className="column is-three-quarters">
      <Video url={`https://www.youtube.com/watch?v=${youtubeId}`} playing={currentPage === index - 1} />
    </div>
  </div>
)

const ImageSlide = ({ children, image, ...props }) => (
  <div className="columns is-vcentered" style={{ width: '100%' }}>
    {image.map((item, index) => (
      <div key={index} className={classNames({ column: true, 'is-half': image.length === 2, 'is-one-third': image.length === 3 })}>
        <ImageCard className="image-card">
          <GatsbyImage image={item.gatsbyImageData} alt="Viktoria Geers" {...props} />
        </ImageCard>
      </div>
    ))}
  </div>
)

const slides = [
  {
    Element: Card,
    props: {
      className: 'card'
    },
    children: <Logo>VIKTORIA</Logo>
  },
  {
    Element: Empty,
    children: (
      <div className="columns is-vcentered is-desktop is-centered has-text-centered">
        <div className="column is-inline-block" style={{ paddingRight: 64 }}>
          <ImageCard className="image-card">
            <StaticImage src="../images/viktoria-geers.jpg" alt="Viktoria Geers" />
            <figcaption>Hallo, ich bin Viktoria.</figcaption>
          </ImageCard>
        </div>
        <div className="column">
          {CV.map(item => (
            <>
              <div className="has-text-left" style={{ paddingTop: 32, paddingBottom: 16 }}>
                <Caption>{item.caption}</Caption>
              </div>
              <div className="has-text-left" style={{ paddingBottom: 32 }}>
                {item.text}
              </div>
            </>
          ))}
        </div>
      </div>
    )
  }
]

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulProject(sort: { fields: publishedDate, order: DESC }) {
          edges {
            node {
              id
              title
              slug
              category {
                name
              }
              description {
                description
              }
              youtubeId
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    const projects = data.allContentfulProject.edges;

    slides.push(...projects.map(project => ({
      Element: project?.node?.image ? ImageSlide : VideoSlide,
      props: project.node
    })));

    slides.push({
      Element: Card,
      props: {
        className: 'card'
      },
      children: <Title>Thank you</Title>
    });
  }, [data]);


  return (
    <ReactFullpage
      navigation
      licenseKey={'77F9085C-DC8B4A5B-B2119A12-C29EE168'}
      scrollingSpeed={1000} /* Options here */
      verticalCentered={true}
      sectionSelector=".page"
      autoScrolling={false}
      fitToSection={false}

      render={({ state, fullpageApi }) => (
        <ReactFullpage.Wrapper>
          {slides.map((slide, index) => (
            <Page key={`slide-${index}`}>
              <slide.Element index={index} {...slide.props}>
                {slide.children}
              </slide.Element>
            </Page>
          ))}
        </ReactFullpage.Wrapper>
      )}
    />
  )
}

export default IndexPage